import http from "../http-common";

class ClientsDataService {
  getAll() {
    return http.get("/users-list")
  }

  getInsurances(id){
    return http.get("/user-insurance/"+ id)
  }

  getSubscribers() {
    return http.get("/subscribers-list")
  }
}

export default new ClientsDataService();