<template>
  <div>
    

    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            Sales
          </CCardHeader>
          <CCardBody>
            
            <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableInsurances"
              :fields="tableFields"
              head-color="light"
              sorter
              :items-per-page="10"
              pagination
              table-filter
            >
              <td slot="id" class="text-center" slot-scope="{item}" :columnFilter='{ external: true, lazy: true }'>
                <div>
                  {{item.id}}
                </div>
              </td>
              <td slot="plano" slot-scope="{item}">
                <div>{{item.plan}}</div>
              </td>
              
              <td slot="qtdPeaple" slot-scope="{item}">
                <div>{{item.people_quantity}} €</div>
              </td>
              <td slot="price" slot-scope="{item}">
                <div>{{item.price}} €</div>
              </td>
              <td slot="payment_status" slot-scope="{item}" :columnFilter='{ external: true, lazy: true }'>
                <CBadge :color="getBadge(item.payment_status)">{{item.payment_status}}</CBadge>
              </td>
              <td slot="action" slot-scope="{item}">
               <CButton block variant="outline" color="primary" @click="getDados(item.id)">Ver</CButton>
              </td>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <CModal
      title="Insurance"
      size="lg"
      :show.sync="largeModal"
      
    >
      <CRow v-for="insurances in this.modalInsurances" :key="insurances">
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong>Information </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Number:</label>
                  <p>{{insurances.id}}</p>
                </div>
                <div class="col-md-2">
                  <label>Plan:</label>
                  <p>{{insurances.plan}}</p>
                </div>
                <div class="col-md-2">
                  <label>Price:</label>
                  <p>{{insurances.price}} €</p>
                </div>
                <div class="col-md-2">
                  <label>Status:</label>
                  <p>
                    <CBadge :color="getBadge(insurances.payment_status)">{{insurances.payment_status}}</CBadge>
                  </p>
                </div>
                <div class="col-md-4">
                  <label>Countries:</label>
                  <p>{{insurances.countries}}</p>
                </div>

                <div class="col-md-4">
                  <label>Initial date:</label>
                  <p>{{insurances.initial_date}}</p>
                </div>
                <div class="col-md-4">
                  <label>Final date:</label>
                  <p>{{insurances.final_date}}</p>
                </div>

                <div class="col-md-12">
                  <label>Método de Pagamento:</label>
                  <p>{{insurances.payment_method}}</p>
                </div>

                <div class="col-md-12">
                  <label>Easypay payment id:</label>
                  <p>{{insurances.easypay_payment_id}}</p>
                </div>

                <div class="col-md-4">
                  <label>Order code:</label>
                  <p>{{insurances.order_code}}</p>
                </div>

                <div class="col-md-6">
                  <label>Status:</label>
                  <div v-if="insurances.status == 0">
                    <p>
                      Apólice não enviada
                    </p>
                  </div>
                  <div v-if="insurances.status == 1">
                    <p>
                      Apólice enviada
                    </p>
                  </div>
                </div>
              </div>
              
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm="12">
          <CCard  v-for="(person, index )  in insurances.persons" :key="index">
            <CCardHeader>
              <strong>Person </strong>{{index +1}} 
              <strong v-if="person.tomador">| Tomador </strong>
              <strong v-if="person.segurado">| Segurado </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Name:</label>
                  <p>{{person.name}}</p>
                </div>
                <div class="col-md-4">
                  <label>Surname:</label>
                  <p>{{person.surname}}</p>
                </div>
                <div class="col-md-4">
                  <label>NIF:</label>
                  <p>{{person.nif}}</p>
                </div>
                 <div class="col-md-4">
                  <label>Cartão Cidadão:</label>
                  <p>{{toUpper(person.cc)}}</p>
                </div>
                <div class="col-md-4">
                  <label>Bilhete de Identidade:</label>
                  <p>{{person.bi}}</p>
                </div>
                <div class="col-md-4">
                  <label>Passaporte:</label>
                  <p>{{person.passport}} - {{person.passport_country}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Date of birth:</label>
                  <p>{{person.date_of_birth}}</p>
                </div>
                <div class="col-md-4">
                  <label>Mobile number:</label>
                  <p>{{person.mobile_number}}</p>
                </div>
                <div class="col-md-4">
                  <label>Email:</label>
                  <p>{{person.email}}</p>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm="12">
          <CCard v-for="(adress, index )  in insurances.addresses" :key="index">
            <CCardHeader>
              <strong>Address Segurado </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>City:</label>
                  <p>{{adress.city}}</p>
                </div>
                <div class="col-md-4">
                  <label>Country:</label>
                  <p>{{adress.country}}</p>
                </div>
                <div class="col-md-4">
                  <label>Postal code:</label>
                  <p>{{adress.postal_code}}</p>
                </div>
                <div class="col-md-6">
                  <label>Location:</label>
                  <p>{{adress.location}}</p>
                </div>
                <div class="col-md-6">
                  <label>Address:</label>
                  <p>{{adress.line_1}}</p>
                </div>
              </div>
              
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <template #footer>
        <CButton @click="largeModal = false" color="success">Ok</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import ClientsDataService from "@/services/ClientsDataService";

export default {
  name: 'Sales',
  // components: {
  //   MainChartExample,
  //   WidgetsDropdown,
  //   WidgetsBrand
  // },
  data () {
    return {
      selected: 'Month',
      tableInsurances: [],
      tableFields: [
        { key: 'id', label: 'Num. Reserva', _classes: 'text-center', sorter: 1 },
        { key: 'plano' },
        { key: 'price' },
        { key: 'people_quantity' },
        { key: 'payment_status' },
        { key: 'action' },
      ],
      largeModal: false,
      modalInsurances: '',
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
    showAdminOrModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR') || this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
  },
  methods: {
    getBadge (status) {
      return status === 'paid' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getDados(id){
      this.modalInsurances = this.tableInsurances.filter(function(event){
        if(event.id == id){
          return event
        }
      })
      this.largeModal = true
    },
    toUpper(str){
        return str ? str.toUpperCase() : ''
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    const id = this.$route.params.id
    ClientsDataService.getInsurances(id)
      .then(response => {

        this.tableInsurances = response.data;

      })
      .catch(e => {
        alert(e);
      });
  }
}
</script>

<style scope="">
  label{
    font-weight: bold;
  }
</style>